import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import LoadingBar from 'react-top-loading-bar'
import { TFunction, withTranslation } from 'react-i18next'
import { useStore } from '../context'
import { unsetLayout } from '../context/actionReducer'
import LanguageSwitcher from './LanguageSwitcher'
import { authService, UIService } from '../services'
import { ROUTES } from '../constants'
import DropdownMenu from './DropdownMenu'
import { ReactComponent as UserIcon } from '../theme/icons/user.svg'
import { toLocaleDateString } from '../helpers/date'
import { PortalConfig } from '../helpers/useConfig'

const HeaderContainer = styled.div`
  background-color: var(--pages-header-backgroundColor);
  color: var(--pages-header-textColor);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 ${(props) => props.theme.gutters.container.sm}px;
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
    padding: 0 ${(props) => props.theme.gutters.container.md}px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}px) {
    padding: 0 ${(props) => props.theme.gutters.container.lg}px;
  }

  .date {
    font-size: 14px;
    margin: 0 auto;

    @media (max-width: 500px) {
      display: none;
    }
  }

  @media (max-width: 500px) {
    position: fixed;
    z-index: 1;
  }
`

const LogoContainer = styled.div`
  display: flex;
`

const VAlign = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    display: flex;
    cursor: pointer;

    &:last-child {
      margin-left: 4px;
    }
  }

  div:last-child {
    margin-left: 10px;
  }
`

const MenuItem = styled.li`
  margin-bottom: 10px;

  &:last-of-type {
    margin: 0;
  }

  button {
    background: transparent;
    border: none;
    color: var(--pages-header-menuTextColor);
    font-size: 14px;
    line-height: 15px;
    cursor: pointer;
  }
`

const LogoutBtn = styled.span`
  cursor: pointer;
  font-size: 14px;
  color: var(--pages-header-menuTextColor);
`

interface HeaderProps {
  config?: PortalConfig
  t: TFunction
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { t, config } = props
  const [, dispatch] = useStore()
  const history = useHistory()
  const { user } = UIService
  const [username, setUserName] = React.useState(user?.username || '')
  const loadRef = React.useRef(null)

  const updateUser = () => {
    const { user } = UIService
    setUserName(user?.username || '')
  }

  React.useEffect(() => {
    window.addEventListener('updateUser', updateUser)

    return () => {
      window.removeEventListener('updateUser', updateUser)
    }
  }, [])

  React.useEffect(() => setUserName(user?.username || ''), [user?.username])

  const logout = () => {
    authService.logout().then(() => history.push(ROUTES.LOGIN))
  }

  const changePassword = () => history.push(ROUTES.CHANGE_PSSW)

  const handleClick = () => dispatch(unsetLayout())

  const navTitle = (
    <VAlign>
      <span>
        <UserIcon
          fill={getComputedStyle(document.documentElement).getPropertyValue(
            '--pages-header-textColor'
          )}
        />
      </span>
    </VAlign>
  )

  const date = new Date()
  let formattedDate = toLocaleDateString(date, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  const handleRef = (el) => {
    loadRef.current = el
    //setRefVisible(!!el)
  }

  return (
    <React.Fragment>
      <LoadingBar
        color={getComputedStyle(document.documentElement).getPropertyValue(
          '--elements-loader-pageColor'
        )}
        height={8}
        ref={handleRef}
      />
      <HeaderContainer id="header">
        <Link to={ROUTES.HOME} onClick={handleClick} style={{ flex: '1' }}>
          <LogoContainer>
            <img src={'/logo.svg'} height={'40px'} alt="Logo" />
          </LogoContainer>
        </Link>

        <div className="date">{formattedDate}</div>

        <VAlign style={{ flex: '1', justifyContent: 'flex-end' }}>
          <LanguageSwitcher config={config} />

          {username && config?.authMode !== 'otp' && (
            <DropdownMenu title={navTitle}>
              <MenuItem>
                <button onClick={changePassword}>
                  {t('profile.changePassword', '')}
                </button>
              </MenuItem>
              <MenuItem>
                <button onClick={logout}>{t('profile.logout', '')}</button>
              </MenuItem>
            </DropdownMenu>
          )}

          {username && config?.authMode === 'otp' && (
            <LogoutBtn onClick={logout}>{t('profile.logout', '')}</LogoutBtn>
          )}
        </VAlign>
      </HeaderContainer>
    </React.Fragment>
  )
}

export default withTranslation()(Header)
